import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { Practitioner } from '../../../core/models/practitioner.model';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit {
  @Input() public patients: Patient[];
  public displayedColumns: string[] = [
    'name',
    'gender',
    'email',
    'socialNumber',
    'enabled',
  ];

  @Output() public rowClick = new EventEmitter<Patient>();

  constructor() {}

  ngOnInit(): void {}

  handleRowClick(row: Patient): void {
    this.rowClick.emit(row);
  }
}
