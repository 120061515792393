import { Injectable } from '@angular/core';

export interface DownloadJsonParams {
  data: object | string;
  fileName?: string;
  space?: string | number;
}

@Injectable()
export class DownloadService {
  getJsonDateURI(content: string): string {
    return 'data:application/json;charset=utf-8,' + encodeURIComponent(content);
  }

  exportJson({
    data,
    fileName = 'download',
    space = 4,
  }: DownloadJsonParams): void {
    const JSONData = JSON.stringify(data, null, space);
    const dataURI = this.getJsonDateURI(JSONData);
    const anchor = document.createElement('a');
    anchor.href = dataURI;

    anchor.download = fileName;
    anchor.setAttribute('style', 'visibility:hidden');

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}
