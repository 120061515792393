import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  Practitioner, PractitionerCreditDto,
  PractitionerPaginationDto,
  UpdatePractitionerDto
} from '../../core/models/practitioner.model';
import { PatientCreditDto, UpdateCreditDto } from '../../core/models/patient.model';
import { PatchEmptyError } from '../../core/errors/patch-empty.error';

@Injectable()
export class PractitionerService {
  private readonly practitionerUrl = environment.api.authUrl + '/practitioners';
  private commercialPractitionerUrl = environment.api.commercialUrl + '/practitioners';
  private readonly graviteeApiKey = environment.graviteeApiKey;

  constructor(private http: HttpClient) {}

  getAll(page?: number, limit?: number): Observable<PractitionerPaginationDto> {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (limit) {
      params = params.set('limit', limit.toString());
    }
    const options = {
      headers: new HttpHeaders({
        'X-Gravitee-Api-Key': this.graviteeApiKey,
      }),
      params,
    };
    return this.http.get<PractitionerPaginationDto>(
      this.practitionerUrl,
      options
    );
  }

  getById(id: string): Observable<Practitioner> {
    return this.http.get<Practitioner>(`${this.practitionerUrl}/${id}`);
  }

  getCredits(practitionerId: string): Observable<PractitionerCreditDto> {
    return this.http.get<PractitionerCreditDto>(
      `${this.commercialPractitionerUrl}/${practitionerId}/credits`
    );
  }

  updateCredits(
    practitionerCreditDto: PractitionerCreditDto,
    updatedCredit: UpdateCreditDto
  ): Observable<any> {
    const toUpdate = {};
    for (const field in updatedCredit) {
      if (updatedCredit.hasOwnProperty(field)) {
        if (updatedCredit[field] !== practitionerCreditDto.credits[field]) {
          toUpdate[field] = updatedCredit[field];
        }
      }
    }

    if (Object.keys(toUpdate).length === 0) {
      return throwError(
        new PatchEmptyError('Aucun changement sur les crédits')
      );
    }

    return this.http.patch(
      `${this.commercialPractitionerUrl}/${practitionerCreditDto.practitionerId}/credits`,
      toUpdate
    );
  }

  update(
    practitioner: Practitioner,
    updatedPractitioner: UpdatePractitionerDto
  ): Observable<any> {
    const toUpdate = {};
    for (const field in updatedPractitioner) {
      if (updatedPractitioner.hasOwnProperty(field)) {
        if (field === 'password' || field === 'confirmationPassword') {
          if (
            updatedPractitioner.password &&
            updatedPractitioner.password ===
              updatedPractitioner.confirmationPassword
          ) {
            toUpdate[field] = updatedPractitioner[field];
          }
        } else if (updatedPractitioner[field] !== practitioner[field]) {
          toUpdate[field] = updatedPractitioner[field];
        }
      }
    }
    return this.http.patch(
      `${this.practitionerUrl}/${practitioner.id}`,
      toUpdate
    );
  }

  updateStatus(practitioner: Practitioner, enabled: boolean): Observable<any> {
    return this.http.patch(`${this.practitionerUrl}/${practitioner.id}`, {
      enabled,
    });
  }
}
