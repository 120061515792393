import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SynthesisOrder } from '../../../core/models/synthesis-order.model';
import { DownloadService } from '../../../core/services/download.service';
import { SynthesisService } from '../../services/synthesis.service';

@Component({
  selector: 'app-synthesis-order',
  templateUrl: './synthesis-order.component.html',
  styleUrls: ['./synthesis-order.component.scss'],
})
export class SynthesisOrderComponent implements OnInit {
  public $synthesisOrder = new BehaviorSubject<SynthesisOrder>(null);
  @Input()
  set synthesisOrder(value) {
    this.$synthesisOrder.next(value);
  }
  get synthesisOrder(): SynthesisOrder {
    return this.$synthesisOrder.getValue();
  }

  constructor(
    private readonly synthesisService: SynthesisService,
    private readonly downloadService: DownloadService
  ) {}

  ngOnInit(): void {}

  downloadJsonReport(synthesisOrderId: string, exportPath: string): void {
    this.synthesisService.getJsonReport(exportPath).subscribe((jsonReport) => {
      this.downloadService.exportJson({
        data: jsonReport,
        fileName: synthesisOrderId,
      });
    });
  }
}
