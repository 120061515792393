<div class="patient-record-container" *ngIf="$patient | async as patient">
  <section>
    <h2>N° de dossier : {{patient.patientCode}}</h2>
    <div class="patient-info-row">
      <div class="patient-info">
        <label>
          <span>Prénom</span>
        </label>
        <div>
          <span>{{patient.firstName}}</span>
        </div>
      </div>
      <div class="patient-info">
        <label>
          <span>Nom</span>
        </label>
        <div>
          <span>{{patient.lastName}}</span>
        </div>
      </div>
      <div class="patient-info">
        <label>
          <span>N° Sécurité sociale</span>
        </label>
        <div>
          <span>{{patient.socialNumber}}</span>
        </div>
      </div>
    </div>
    <div class="patient-info-row">
      <div class="patient-info">
        <label>
          <span>Date de naissance</span>
        </label>
        <div>
          <span *ngIf="patient.dateOfBirth">{{patient.dateOfBirth | date:'dd/MM/yyyy' }}</span>
        </div>
      </div>
      <div class="patient-info">
        <label>
          <span>E-mail</span>
        </label>
        <div>
          <span>{{patient.email}}</span>
        </div>
      </div>
      <div class="patient-info">
        <label>
          <span>Téléphone</span>
        </label>
        <div>
          <span>{{patient.phoneNumber}}</span>
        </div>
      </div>
    </div>
    <button mat-raised-button color="primary" (click)="handleRegisterRequest()">Créer le patient</button>
  </section>
</div>
