import { Component, OnInit } from '@angular/core';
import {
  SynthesisOrder,
  SynthesisOrderPaginationDto,
} from '../../../core/models/synthesis-order.model';
import { SynthesisService } from '../../services/synthesis.service';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { from, of } from 'rxjs';
import { bufferCount, concatMap, delay, filter, retryWhen, take } from 'rxjs/operators';

const INDIVIDUAL_FETCH_BUFFER = 15;
const INDIVIDUAL_FETCH_DELAY = 5000;

@Component({
  selector: 'app-synthesis-orders',
  templateUrl: './synthesis-orders.component.html',
  styleUrls: ['./synthesis-orders.component.scss'],
})
export class SynthesisOrdersComponent implements OnInit {
  public synthesisOrderPagination: SynthesisOrderPaginationDto;
  public synthesisOrders: SynthesisOrder[] = [];

  constructor(
    private readonly synthesisService: SynthesisService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.fetchPage();
  }

  fetchPage(page?: number): void {
    this.synthesisService
      .getAllOrders(page, 50)
      .subscribe((synthesisOrderPagination) => {
        this.synthesisOrderPagination = synthesisOrderPagination;
        this.synthesisOrders = synthesisOrderPagination.items;
        this.handleIndividualOrders();
      });
  }

  handlePage(pageEvent: PageEvent): void {
    this.fetchPage(pageEvent.pageIndex);
  }

  handleRowClick(synthesisOrder: SynthesisOrder): void {
    this.router.navigate([
      'dashboard',
      'synthesis-orders',
      'show',
      synthesisOrder.id,
    ]);
  }

  /**
   * Updates every order to get user first and last names. Forces to make an HTTP request for every single order...
   * Could be done in reporting back-end, but would still require one request per order and would take a longer time to display all orders
   *
   * We fetch orders by group (bufferCount()) and force a delay between each call (from(_).pipe(delay())
   */
  handleIndividualOrders(): void {
    from(this.synthesisOrders).pipe(
      filter(synthesisOrder => synthesisOrder.user === undefined),
      bufferCount(INDIVIDUAL_FETCH_BUFFER),
      concatMap(_ => from(_).pipe(delay(INDIVIDUAL_FETCH_DELAY))),
      concatMap(synthesisOrder => this.synthesisService.getOrderById(synthesisOrder.id)),
      concatMap((order, i) => {
        this.synthesisOrders[i].user = order.user;
        return of(order);
      }),
      retryWhen(errors => {
        this.openSnackBar('Trop de requêtes simultanées. Relance automatique dans une minute.');
        // Retry twice after 1 minute
        return errors.pipe(
          delay(60000),
          take(2),
        );
      }),
    ).subscribe(_ => {}, err => {
      console.log(err);
      this.openSnackBar('Erreur lors du chargement des noms et prénoms.');
    });
  }

  private openSnackBar(message: string): void {
    this.snackBar.open(message, '', { duration: 30000, panelClass: ['mat-toolbar', 'mat-warn'] });
  }
}
