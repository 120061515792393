<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <hr />
  <!-- <img
    mat-card-image
    src="../../../../../assets/images/product.png"
    alt="Image of product"
  /> -->

  <mat-card-content>
    <p>Full: {{ full }}</p>
    <p>Treatment: {{ treatment }}</p>
    <p>Consultation: {{ consultation }}</p>
    <p>Box: {{ box }}</p>
  </mat-card-content>
  <mat-card-actions class="button-container">
    <button
      mat-stroked-button
      class="button-generate"
      (click)="openDialog(title)"
    >
      Générer
    </button>
  </mat-card-actions>
</mat-card>
