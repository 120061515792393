import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SynthesisOrder } from '../../../core/models/synthesis-order.model';

@Component({
  selector: 'app-synthesis-orders-list',
  templateUrl: './synthesis-orders-list.component.html',
  styleUrls: ['./synthesis-orders-list.component.scss'],
})
export class SynthesisOrdersListComponent implements OnInit {
  @Input() public synthesisOrders: SynthesisOrder[];
  public displayedColumns: string[] = [
    'reportCreatedDate',
    'createdDate',
    'userRole',
    'status',
  ];

  @Output() public rowClick = new EventEmitter<SynthesisOrder>();

  constructor() {}

  ngOnInit(): void {}

  handleRowClick(row: SynthesisOrder): void {
    this.rowClick.emit(row);
  }
}
