<ng-container *ngIf="$patient | async as patient">
  <ng-container *ngIf="patient.enabled; then disableButton else enableButton"></ng-container>
  <ng-template #enableButton>
    <button mat-raised-button color="green" (click)="handleSwitchStatus(true)">Activer le compte</button>
  </ng-template>
  <ng-template #disableButton>
    <button mat-raised-button color="warn" (click)="handleSwitchStatus(false)">Désactiver le compte</button>
  </ng-template>
</ng-container>

