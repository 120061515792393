<table
  mat-table
  [dataSource]='synthesisOrders'
  class='mat-elevation-z4'>
  <!-- Report created date -->
  <ng-container matColumnDef='reportCreatedDate'>
    <th
      mat-header-cell
      *matHeaderCellDef>Date de création du score
    </th>
    <td
      mat-cell
      *matCellDef='let synthesisOrder'>{{synthesisOrder.reportCreatedDate | date:'dd/MM/yyyy'}}</td>
  </ng-container>

  <!-- Synthesis order create date Column -->
  <ng-container matColumnDef='createdDate'>
    <th
      mat-header-cell
      *matHeaderCellDef>Date de demande
    </th>
    <td
      mat-cell
      *matCellDef='let synthesisOrder'>{{synthesisOrder.createdDate | date:'dd/MM/yyyy'}}</td>
  </ng-container>

  <!-- Synthesis user role Column -->
  <ng-container matColumnDef='userRole'>
    <th
      mat-header-cell
      *matHeaderCellDef>Origine de la demande
    </th>
    <td
      mat-cell
      *matCellDef='let synthesisOrder'>{{synthesisOrder.userRole | titlecase}}
      <span *ngIf="synthesisOrder.user; else loadingSpinner">({{synthesisOrder.user.firstName}} {{synthesisOrder.user.lastName}})</span>
    </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef='status'>
    <th
      mat-header-cell
      *matHeaderCellDef>Statut
    </th>
    <td
      mat-cell
      *matCellDef='let synthesisOrder'>
      <span class='badge'
            [class]="{'primary': synthesisOrder.status === 'COMPLETED', 'warning': synthesisOrder.status === 'REQUESTED'}">{{"SYNTHESIS.STATUS." + synthesisOrder.status | translate}}</span>
    </td>
  </ng-container>
  <tr
    mat-header-row
    *matHeaderRowDef='displayedColumns'></tr>
  <tr
    mat-row
    class='synthesis-orders-row'
    *matRowDef='let row; columns: displayedColumns;'
    (click)='handleRowClick(row)'></tr>
</table>

<ng-template #loadingSpinner>
  <mat-spinner [diameter]="15"></mat-spinner>
</ng-template>
