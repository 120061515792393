import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FileInput } from 'ngx-material-file-input';
import { SynthesisOrder } from '../../../core/models/synthesis-order.model';
import { BehaviorSubject } from 'rxjs';
import { SynthesisService } from '../../services/synthesis.service';
import { takeWhile } from 'rxjs/operators';
import { Synthesis } from '../../../core/models/synthesis.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-synthesis-file',
  templateUrl: './synthesis-file.component.html',
  styleUrls: ['./synthesis-file.component.scss'],
})
export class SynthesisFileComponent implements OnInit {
  public $synthesisOrder = new BehaviorSubject<SynthesisOrder>(null);
  @Input()
  set synthesisOrder(value) {
    this.$synthesisOrder.next(value);
  }
  get synthesisOrder(): SynthesisOrder {
    return this.$synthesisOrder.getValue();
  }

  @Output() upload = new EventEmitter<void>();

  public synthesis: Synthesis;

  public synthesisForm: FormGroup;

  constructor(
    private readonly synthesisService: SynthesisService,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.handleSynthesisOrder();
  }

  initForm(): void {
    this.synthesisForm = new FormGroup({
      synthesisFile: new FormControl(null, [Validators.required]),
    });
  }

  handleSynthesisOrder(): void {
    this.$synthesisOrder
      .pipe(
        takeWhile(
          (synthesisOrder) =>
            synthesisOrder === undefined || synthesisOrder === null,
          true
        )
      )
      .subscribe((synthesisOrder) => {
        if (synthesisOrder.status === 'COMPLETED') {
          this.fetchSynthesis(synthesisOrder.id);
        }
      });
  }

  fetchSynthesis(synthesisOrderId: string): void {
    this.synthesisService
      .getByOrderId(synthesisOrderId)
      .subscribe((synthesis) => (this.synthesis = synthesis));
  }

  handleSubmit(): any {
    if (this.synthesisForm.valid) {
      const fileInput: FileInput = this.synthesisForm.value.synthesisFile;

      this.synthesisService
        .upload({
          file: fileInput.files[0],
          synthesisOrderId: this.synthesisOrder.id,
        })
        .subscribe((_) => {
          this.upload.emit();
        }, error => {
          console.log(error);
          this.snackBar.open(
            `Une erreur est survenue lors de l'envoi de la synthèse : ${error.statusText}`,
            '',
            { verticalPosition: 'top', horizontalPosition: 'right' },
          );
        });
    }
  }
}
