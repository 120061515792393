import { Component, OnInit } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { merge, throwError, zip } from 'rxjs';
import { PatientService } from '../../services/patient.service';
import {
  Patient,
  PatientCreditDto,
  UpdatePatientDto,
} from '../../../core/models/patient.model';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PatchEmptyError } from '../../../core/errors/patch-empty.error';

@Component({
  selector: 'app-edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['./edit-patient.component.scss'],
})
export class EditPatientComponent implements OnInit {
  public loaded = false;
  public patient: Patient;
  public patientCredit: PatientCreditDto;
  public requestRegister = false;

  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchPatient();
  }

  fetchPatient(): void {
    this.loaded = false;
    this.route.paramMap
      .pipe(
        take(1),
        map((paramMap) => {
          if (paramMap.get('patientId')) {
            return paramMap.get('patientId');
          }
          throwError('No id provide');
        }),
        switchMap((patientId: string) => {
          return zip(
            this.patientService.getById(patientId),
            this.patientService.getCredits(patientId)
          ).pipe(take(1));
        })
      )
      .subscribe((result) => {
        const [patient, patientCredit] = result;
        this.patient = patient;
        this.patientCredit = patientCredit;
        this.loaded = true;
      });
  }

  get isPatientRecord(): boolean {
    if (!this.patient) {
      return false;
    }
    return !this.patient.enabled && !!this.patient.patientCode;
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, '', { duration: 10000 });
  }

  handleSuccess(message: string): void {
    this.openSnackBar(message);
    this.fetchPatient();
  }

  handleError(message: string): void {
    this.openSnackBar(message);
  }

  handleRegisterRequest(): void {
    this.requestRegister = true;
  }

  handleRegisterPatient(values: UpdatePatientDto): void {
    this.patientService.registerByPatientCode(this.patient, values).subscribe(
      () => {
        this.handleSuccess('Enregistrement effectué');
      },
      (error) => {
        if (error.status && error.status === 500) {
          this.handleError(
            `Impossible de créer le patient. Vérifier que le mail ou le N° de sécurité sociale n'est pas déjà attribué`
          );
        }
      }
    );
  }

  handleEditPatient(values: UpdatePatientDto): void {
    this.patientService.update(this.patient, values).subscribe(
      () => {
        this.handleSuccess('Modification effectuée');
      },
      (error) => {
        if (error instanceof PatchEmptyError) {
          this.handleError(error.message);
        }
      }
    );
  }
}
