import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import {
  Patient,
  PatientPaginationDto,
} from '../../../core/models/patient.model';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
})
export class PatientsComponent implements OnInit {
  public patientPagination: PatientPaginationDto;
  public patients: Patient[] = [];

  constructor(private patientService: PatientService, private router: Router) {}

  ngOnInit(): void {
    this.fetchPage();
  }

  fetchPage(page?: number): void {
    this.patientService.getAll(page, 9999).subscribe((patientPagination) => {
      this.patientPagination = patientPagination;
      this.patients = patientPagination.items;
    });
  }

  handleRowClick(patient: Patient): void {
    this.router.navigate(['dashboard', 'patients', 'edit', patient.id]);
  }

  handlePage(pageEvent: PageEvent): void {
    this.fetchPage(pageEvent.pageIndex);
  }
}
