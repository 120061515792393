import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Practitioner } from '../../../core/models/practitioner.model';

@Component({
  selector: 'app-praticioners-list',
  templateUrl: './praticioners-list.component.html',
  styleUrls: ['./praticioners-list.component.scss'],
})
export class PraticionersListComponent implements OnInit {
  @Input() public practitioners: Practitioner[];
  public displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'rpps',
    'enabled',
  ];

  @Output() public rowClick = new EventEmitter<Practitioner>();

  constructor() {}

  ngOnInit(): void {}

  handleRowClick(row: Practitioner): void {
    this.rowClick.emit(row);
  }
}
