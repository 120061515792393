<br />

<fieldset *ngIf="!ordered_package_ready">
  <legend><h1>Veuillez sélectionner votre package à générer</h1></legend>
  <div class="container">
    <app-product-card
      *ngFor="let product of all_product_types"
      [title]="product.name"
      [type]="product.type"
      [content_object_full]="product.full"
      [content_object_treatment]="product.treatment"
      [content_object_consultation]="product.consultation"
      [content_object_box]="product.box"
    ></app-product-card>
  </div>
</fieldset>

<fieldset *ngIf="ordered_package_ready">
  <legend><h1>Les clés suivantes ont été générées avec succès</h1></legend>
  <app-order-result></app-order-result>
</fieldset>
