import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PractitionerService } from '../../services/practitioner.service';
import { map, switchMap, take } from 'rxjs/operators';
import { throwError, zip } from 'rxjs';
import { Practitioner, PractitionerCreditDto } from '../../../core/models/practitioner.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-edit-practitioner',
  templateUrl: './edit-practitioner.component.html',
  styleUrls: ['./edit-practitioner.component.scss'],
})
export class EditPractitionerComponent implements OnInit {
  public loaded = false;
  public practitioner: Practitioner;
  public practitionerCredit: PractitionerCreditDto;

  constructor(
    private route: ActivatedRoute,
    private practitionerService: PractitionerService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchPractitioner();
  }

  fetchPractitioner(): void {
    this.loaded = false;
    this.route.paramMap
      .pipe(
        take(1),
        map((paramMap) => {
          if (paramMap.get('practitionerId')) {
            return paramMap.get('practitionerId');
          }
          throwError('No id provide');
        }),
        switchMap((practitionerId: string) => {
          return zip(
            this.practitionerService.getById(practitionerId),
            this.practitionerService.getCredits(practitionerId)
          ).pipe(take(1));
        })
      )
      .subscribe((result) => {
        const [practitioner, practitionerCredit] = result;
        this.practitioner = practitioner;
        this.practitionerCredit = practitionerCredit;
        this.loaded = true;
      });
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, '', { duration: 5000 });
  }

  handleSuccess(message: string): void {
    this.openSnackBar(message);
    this.fetchPractitioner();
  }

  handleError(message: string): void {
    this.openSnackBar(message);
  }
}
