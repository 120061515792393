import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

export interface DatepickerDialogData {
  validationButtonLabel: string;
}

@Component({
  selector: 'app-exports-datepicker',
  templateUrl: './exports-datepicker.component.html',
  styleUrls: ['./exports-datepicker.component.scss']
})
export class ExportsDatepickerComponent implements OnInit {
  private defaultStartDate: Date;
  private defaultEndDate: Date;
  public range: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DatepickerDialogData,
  ) {}

  ngOnInit(): void {
    this.initializeDates();
    this.initForm();
  }

  initForm(): void {
    this.range = new FormGroup({
      start: new FormControl(this.defaultStartDate),
      end: new FormControl(this.defaultEndDate),
    });
  }

  initializeDates(): void {
    const today = new Date();

    // By default, dates from previous month
    this.defaultStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    this.defaultEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
  }
}
