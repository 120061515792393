import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreditValidator } from '../../../core/validators/credit.validator';
import { PatientCreditDto } from '../../../core/models/patient.model';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { PatientService } from '../../services/patient.service';
import { PatchEmptyError } from '../../../core/errors/patch-empty.error';

@Component({
  selector: 'app-credits-patient-form',
  templateUrl: './credits-patient-form.component.html',
  styleUrls: ['./credits-patient-form.component.scss'],
})
export class CreditsPatientFormComponent implements OnInit {
  public $patientCredit = new BehaviorSubject<PatientCreditDto>(null);
  @Input()
  set patientCredit(value: PatientCreditDto) {
    this.$patientCredit.next(value);
  }
  get patientCredit(): PatientCreditDto {
    return this.$patientCredit.getValue();
  }

  @Output() succeed = new EventEmitter<string>();
  @Output() submitError = new EventEmitter<string>();

  public creditsForm: FormGroup;

  constructor(private patientService: PatientService) {}

  ngOnInit(): void {
    this.initForm();
    this.setFormValues();
  }

  initForm(): void {
    this.creditsForm = new FormGroup({
      full: new FormControl('', [
        Validators.required,
        CreditValidator.positiveInteger,
      ]),
      treatment: new FormControl('', [
        Validators.required,
        CreditValidator.positiveInteger,
      ]),
      consultation: new FormControl('', [
        Validators.required,
        CreditValidator.positiveInteger,
      ]),
      box: new FormControl('', [
        Validators.required,
        CreditValidator.positiveInteger
      ])
    });
  }

  setFormValues(): void {
    this.$patientCredit
      .pipe(
        takeWhile(
          (patientCredit) =>
            patientCredit === undefined || patientCredit === null,
          true
        )
      )
      .subscribe((patientCredit) => {
        if (patientCredit) {
          this.creditsForm.patchValue({
            full: +patientCredit.credits.full,
            treatment: +patientCredit.credits.treatment,
            consultation: +patientCredit.credits.consultation,
            box: +patientCredit.credits.box,
          });
        }
      });
  }

  getFormValue(name: string): string {
    return this.creditsForm.get(name).value;
  }

  handleSubmit(): void {
    if (this.creditsForm.valid) {
      this.patientService
        .updateCredits(this.patientCredit, {
          full: +this.creditsForm.value.full,
          treatment: +this.creditsForm.value.treatment,
          consultation: +this.creditsForm.value.consultation,
          box: +this.creditsForm.value.box,
        })
        .subscribe(
          () => {
            this.succeed.emit('Modification des crédits effectuée');
          },
          (error) => {
            if (error instanceof PatchEmptyError) {
              this.submitError.emit(error.message);
            }
          }
        );
    }
  }
}
