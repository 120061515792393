import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivationKeyService {
  package_data = new BehaviorSubject<any>(null);
  ordered_package = new BehaviorSubject<any>(false);
  constructor(private http: HttpClient) {}

  updatePackageData(data: any) {
    this.package_data.next(data);
  }
  generatePackage(values: any) {
    console.log(values);

    return this.http.post(
      'https://gravitee-gw-dev-01.healthpilot.tech/commercial/activationkeys',
      values
    );
  }
  packageIsReady() {
    return this.ordered_package.asObservable();
  }
}
