<mat-form-field class="input-container">
  <ngx-mat-intl-tel-input
    class="app-input"
    appControlErrors
    [preferredCountries]="['fr', 'us', 'gb']"
    [enablePlaceholder]="true"
    [formControl]="control"
    (countryChanged)="handleCountryChanged($event)"
    (controlError)="handleError($event)"
    name="phone"></ngx-mat-intl-tel-input>
  <mat-icon [matTooltip]="'Ex : ' + placeholder" matSuffix>info</mat-icon>
  <mat-hint *ngIf="placeholder">Ex : {{placeholder}}</mat-hint>
  <mat-error *ngIf="$error | async">{{$error | async}}</mat-error>
</mat-form-field>

