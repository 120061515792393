<table
  mat-table
  [dataSource]="practitioners"
  class="mat-elevation-z4">
  <!-- Name Column (FirstName + LastName) -->
  <ng-container matColumnDef="name">
    <th
      mat-header-cell
      *matHeaderCellDef>Praticien
    </th>
    <td
      mat-cell
      *matCellDef="let practitioner">{{practitioner.firstName}} {{practitioner.lastName}}</td>
  </ng-container>

  <!-- E-mail Column -->
  <ng-container matColumnDef="email">
    <th
      mat-header-cell
      *matHeaderCellDef>E-mail
    </th>
    <td
      mat-cell
      *matCellDef="let practitioner">{{practitioner.email}}</td>
  </ng-container>

  <!-- Phone Column -->
  <ng-container matColumnDef="phone">
    <th
      mat-header-cell
      *matHeaderCellDef>Téléphone
    </th>
    <td
      mat-cell
      *matCellDef="let practitioner">{{practitioner.phoneNumber}}</td>
  </ng-container>

  <!-- RPPS Column -->
  <ng-container matColumnDef="rpps">
    <th
      mat-header-cell
      *matHeaderCellDef>N° RPPS
    </th>
    <td
      mat-cell
      *matCellDef="let practitioner">{{practitioner.identificationNumber}}</td>
  </ng-container>

  <!-- RPPS Column -->
  <ng-container matColumnDef="enabled">
    <th
      mat-header-cell
      *matHeaderCellDef>Actif / Inactif
    </th>
    <td
      mat-cell
      *matCellDef="let practitioner"><span class="badge" [class]="practitioner.enabled ? 'primary' : 'warn'">{{practitioner.enabled ? 'Actif' : 'Inactif'}}</span></td>
  </ng-container>
  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    class="practitioner-row"
    *matRowDef="let row; columns: displayedColumns;"
    (click)="handleRowClick(row)"></tr>
</table>
