<div class="credits-form-container">
  <form [formGroup]="creditsForm" (ngSubmit)="handleSubmit()">
    <section>
      <h2>Crédits</h2>
      <div class="input-row">
        <app-input type="number" formControlName="full" label="Profil de santé" [value]="getFormValue('full')"></app-input>
      </div>
    </section>
    <button mat-raised-button color="primary" type="submit">Enregistrer</button>
  </form>
</div>
