<div class="edit-patient-container">
  <ng-container *ngIf="loaded">
    <ng-container *ngIf="isPatientRecord; then patientRecord else editPatient"></ng-container>
    <ng-template #patientRecord>
      <ng-container *ngIf="requestRegister; then registerPatient; else showPatientRecord"></ng-container>
      <ng-template #registerPatient>
        <div class="box">
          <app-edit-patient-form [patient]="patient" translation="REGISTER-PATIENT-RECORD" [passwordRequired]="true" (formValues)="handleRegisterPatient($event)"></app-edit-patient-form>
        </div>
      </ng-template>
      <ng-template #showPatientRecord>
        <div class="box patient-record">
          <app-patient-record [patient]="patient" (registerRequest)="handleRegisterRequest()"></app-patient-record>
        </div>
      </ng-template>
    </ng-template>
    <ng-template #editPatient>
      <div class="box">
        <app-patient-switch-status [patient]="patient" (switch)="handleSuccess($event)"></app-patient-switch-status>
        <app-edit-patient-form [patient]="patient" translation="EDIT-PATIENT-FORM" [passwordRequired]="false" (formValues)="handleEditPatient($event)"></app-edit-patient-form>
      </div>
      <div class="box">
        <app-credits-patient-form [patientCredit]="patientCredit" (succeed)="handleSuccess($event)" (submitError)="handleError($event)" ></app-credits-patient-form>
      </div>
    </ng-template>
  </ng-container>
</div>


