<mat-form-field class="input-container">
  <mat-label>{{label}}</mat-label>
  <input
    class="app-input"
    matInput
    appControlErrors
    [formControl]="control"
    [value]="value"
    [type]="type"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (controlError)="handleError($event)"
    autocomplete="off"
    (input)="onChange($event.target.value)">
  <mat-error *ngIf="$error | async">{{$error | async}}</mat-error>
</mat-form-field>

