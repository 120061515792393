<div>
  <mat-form-field appearance="fill">
    <mat-label>Période</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Début">
      <input matEndDate formControlName="end" placeholder="Fin">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>

  <button mat-button mat-dialog-close color="warn">
    <mat-icon>close</mat-icon>
    Annuler
  </button>
  <button mat-flat-button mat-dialog-close="{{ range.value | json }}" color="primary">
    <mat-icon>file_upload</mat-icon>
    {{ data.validationButtonLabel }}
  </button>
</div>
