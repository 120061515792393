<h2 mat-dialog-title>Nouvelle clé d'activation</h2>
<mat-dialog-content class="mat-typography">
  <fieldset>
    <legend>
      <h3><b> {{package_info.title}}</b></h3>
    </legend>
    <h3>Full: {{package_info.full}}</h3>
    <h3>Treatment: {{package_info.treatment}}</h3>
    <h3>Consultation: {{package_info.consultation}}</h3>
    <h3>Box: {{package_info.box}}</h3>
  </fieldset>

  <div>
    <table>
      <tr>
        <td>Campany Name:</td>
        <td><input type="text" name="company_name" #company_type /></td>
      </tr>
      <tr>
        <td>Campaign Type:</td>
        <td>
          <select name="campaign_type" id="campaign_type" #campaign_type>
            <option value="fixed">Fixed</option>
            <option value="continuous">Continuous</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Quantity:</td>
        <td>
          <button mat-raised-button color="warn" (click)="onDecrease()">
            -</button
          ><input
            type="text"
            [(ngModel)]="counter"
            name="test"
            class="counter-box"
          />
          <button mat-raised-button color="green" (click)="onIncrease()">
            +
          </button>
        </td>
      </tr>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-button
    [mat-dialog-close]="true"
    class="generate-button"
    (click)="generate(company_type.value,campaign_type.value,counter,package_info.type)"
    mat-stroked-button
  >
    Générer
  </button>
</mat-dialog-actions>
