import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: `Ce champ est obligatoire`,
  strenghPassword:
    'Doit contenir 10 caractères, 1 majuscule, 1 miniscule, 1 chiffre, 1 caractère spécial',
  matchPassword: 'Les deux champs doivent correspondre',
  email: `Email non valide`,
  invalidDateFormat: `La date de naissance doit être au format dd/mm/yyyy`,
  validatePhoneNumber: `Le n° de téléphone est invalide`,
  positiveInteger: `Le crédit doit être un entier positif ou égale à zéro`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors,
});
