import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {
  JsonReport,
  SynthesisOrder,
  SynthesisOrderPaginationDto,
} from '../../core/models/synthesis-order.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Synthesis,
  UploadSynthesisDto,
} from '../../core/models/synthesis.model';

@Injectable()
export class SynthesisService {
  private synthesisOrderUrl =
    environment.api.reportingUrl + '/synthesis-orders';
  private readonly uploadSynthesisEndpoint: string;
  constructor(private readonly http: HttpClient) {
    this.uploadSynthesisEndpoint = this.synthesisOrderUrl + '/synthesis';
  }

  getAllOrders(
    page?: number,
    limit?: number
  ): Observable<SynthesisOrderPaginationDto> {
    let params = new HttpParams();
    if (page) {
      params = params.set('page', page.toString());
    }
    if (limit) {
      params = params.set('limit', limit.toString());
    }
    return this.http.get<SynthesisOrderPaginationDto>(
      `${this.synthesisOrderUrl}`,
      {
        params,
      }
    );
  }

  getOrderById(id: string): Observable<SynthesisOrder> {
    return this.http.get<SynthesisOrder>(`${this.synthesisOrderUrl}/${id}`);
  }

  getJsonReport(path: string): Observable<any> {
    return this.http.get<JsonReport>(`${environment.api.reportingUrl}${path}`);
  }

  getByOrderId(id: string): Observable<Synthesis> {
    return this.http.get<Synthesis>(
      `${this.synthesisOrderUrl}/${id}/synthesis`
    );
  }

  upload(synthesisFileDto: UploadSynthesisDto): Observable<Synthesis> {
    const formData = new FormData();
    formData.append('fileEntityClass', 'synthesis');
    formData.append('fileEntityId', synthesisFileDto.synthesisOrderId);
    formData.append('file', synthesisFileDto.file);

    return this.http.post<Synthesis>(this.uploadSynthesisEndpoint, formData);
  }
}
