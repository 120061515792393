import { Component, OnInit } from '@angular/core';
import { PractitionerService } from '../../services/practitioner.service';
import {
  Practitioner,
  PractitionerPaginationDto,
} from '../../../core/models/practitioner.model';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-practitioners',
  templateUrl: './practitioners.component.html',
  styleUrls: ['./practitioners.component.scss'],
})
export class PractitionersComponent implements OnInit {
  public practitionerPagination: PractitionerPaginationDto;
  public practitioners: Practitioner[] = [];

  constructor(
    private practitionerService: PractitionerService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchPage();
  }

  fetchPage(page?: number): void {
    this.practitionerService
      .getAll(page, 9999)
      .subscribe((practitionerPagination) => {
        this.practitionerPagination = practitionerPagination;
        this.practitioners = practitionerPagination.items;
      });
  }

  handlePage(pageEvent: PageEvent): void {
    this.fetchPage(pageEvent.pageIndex);
  }

  handleRowClick(practitioner: Practitioner): void {
    this.router.navigate([
      'dashboard',
      'practitioners',
      'edit',
      practitioner.id,
    ]);
  }
}
