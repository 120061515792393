export class Patient {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  password: string;
  confirmationPassword: string;
  email: string;
  zipCode = '';
  city = '';
  country = '';
  address = '';
  addressInfo = '';
  phoneNumber: string;
  socialNumber: string;
  gender: string;
  reportsVisibility = '';
  userId: string;
  patientCode: string;
  enabled: boolean;
}

export interface PatientCreditDto {
  credits: { [key: string]: number };
  patientId: string;
  userId: string;
}

export interface UpdatePatientDto {
  gender: string;
  firstName: string;
  lastName: string;
  email: string;
  socialNumber: string;
  phoneNumber: string;
  dateOfBirth: string;
  password: string;
  confirmationPassword: string;
}

export interface UpdateCreditDto {
  [key: string]: number;
}

export interface PatientPaginationDto {
  items: Patient[];
  meta: {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}
