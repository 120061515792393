<div class="synthesis-order-container" *ngIf="$synthesisOrder | async as synthesisOrder">
  <section>
    <div class="section-heading">
      <h2>Demande de synthèse</h2>
      <span class="badge"
            [class]="{'primary': synthesisOrder.status === 'COMPLETED', 'warning': synthesisOrder.status === 'REQUESTED'}">{{"SYNTHESIS.STATUS." + synthesisOrder.status | translate}}</span>
    </div>
    <div class="synthesis-order-info-row">
      <div class="synthesis-order-info">
        <label>
          <span>Date de création du score</span>
        </label>
        <div>
          <span>{{synthesisOrder.reportCreatedDate | date:'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <div class="synthesis-order-info">
        <label>
          <span>Date de demande</span>
        </label>
        <div>
          <span>{{synthesisOrder.createdDate | date:'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <div class="synthesis-order-info">
        <label>
          <span>Origine</span>
        </label>
        <div>
          <span>{{synthesisOrder.userRole | titlecase}} ({{ synthesisOrder.user.firstName}} {{ synthesisOrder.user.lastName}})</span>
        </div>
      </div>
      <div class="synthesis-order-info">
        <label>
          <span>Détails du score</span>
        </label>
        <div>
          <button mat-button color="primary" (click)="downloadJsonReport(synthesisOrder.id, synthesisOrder.export)">
            <svg class="download-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path
                d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
            </svg>
            EXPORTER
          </button>
        </div>
      </div>
    </div>
  </section>
</div>
