import { FormControl } from '@angular/forms';

export class CreditValidator {
  static positiveInteger(control: FormControl): { [error: string]: any } {
    if (isNormalInteger(control.value)) {
      return null;
    }
    return {
      positiveInteger: true,
    };
  }
}

function isNormalInteger(value: string | number): boolean {
  if (typeof value === 'string') {
    value = Number(value);
  }
  const n = Math.floor(value);
  return n !== Infinity && n === value && n >= 0;
}
