import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ControlErrorDirectiveModule } from '../../core/directives/control-error/control-error-directive.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TelInputComponent } from './tel-input.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [TelInputComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    ControlErrorDirectiveModule,
    ReactiveFormsModule,
    NgxMatIntlTelInputModule,
    MatIconModule,
    MatTooltipModule,
  ],
  exports: [TelInputComponent],
})
export class TelInputModule {}
