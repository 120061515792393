<div class="synthesis-order-container">
  <ng-container *ngIf="loaded">
    <div class="box">
      <app-synthesis-order [synthesisOrder]="synthesisOrder"></app-synthesis-order>
    </div>
    <div class="box synthesis-file">
      <app-synthesis-file [synthesisOrder]="synthesisOrder" (upload)="handleFileUploaded()"></app-synthesis-file>
    </div>
  </ng-container>
</div>
