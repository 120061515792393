<table
  mat-table
  [dataSource]="patients"
  class="mat-elevation-z4">
  <!-- Name Column (FirstName + LastName) -->
  <ng-container matColumnDef="name">
    <th
      mat-header-cell
      *matHeaderCellDef>Patient
    </th>
    <td
      mat-cell
      *matCellDef="let patient">{{patient.firstName}} {{patient.lastName}}</td>
  </ng-container>
  <!-- Gender Column -->
  <ng-container matColumnDef="gender">
    <th
      mat-header-cell
      *matHeaderCellDef>Genre
    </th>
    <td
      mat-cell
      *matCellDef="let patient">{{ patient.gender === 'F' ? 'Femme' : 'Homme' }}</td>
  </ng-container>
  <!-- E-mail Column -->
  <ng-container matColumnDef="email">
    <th
      mat-header-cell
      *matHeaderCellDef>E-mail
    </th>
    <td
      mat-cell
      *matCellDef="let patient">{{patient.email}}</td>
  </ng-container>

  <!-- N° Sécurite sociale Column -->
  <ng-container matColumnDef="socialNumber">
    <th
      mat-header-cell
      *matHeaderCellDef>N° Sécurité sociale
    </th>
    <td
      mat-cell
      *matCellDef="let patient">{{patient.socialNumber}}</td>
  </ng-container>

  <ng-container matColumnDef="enabled">
    <th
      mat-header-cell
      *matHeaderCellDef>Actif / Inactif
    </th>
    <td
      mat-cell
      *matCellDef="let patient"><span class="badge" [class]="patient.enabled ? 'primary' : 'warn'">{{patient.enabled ? 'Actif' : 'Inactif'}}</span></td>
  </ng-container>
  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    class="patient-row"
    *matRowDef="let row; columns: displayedColumns;"
    (click)="handleRowClick(row)"></tr>
</table>
