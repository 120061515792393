<div class="sidebar">
  <mat-nav-list>
    <a mat-list-item routerLink="patients" routerLinkActive="active"
      >Patients</a
    >
    <a mat-list-item routerLink="practitioners" routerLinkActive="active"
      >Praticiens</a
    >
    <a mat-list-item routerLink="synthesis-orders" routerLinkActive="active"
      >Demandes de synthèse</a
    >
    <a mat-list-item routerLink="exports" routerLinkActive="active">Exports</a>
    <a
      mat-list-item
      routerLink="activation-key"
      routerLinkActive="active"
      (click)="activationKeyHandler()"
      >Clé d'activation
    </a>
  </mat-nav-list>
</div>
