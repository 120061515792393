import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { BehaviorSubject } from 'rxjs';
import { PatientService } from '../../services/patient.service';

@Component({
  selector: 'app-patient-switch-status',
  templateUrl: './patient-switch-status.component.html',
  styleUrls: ['./patient-switch-status.component.scss'],
})
export class PatientSwitchStatusComponent implements OnInit {
  public $patient = new BehaviorSubject<Patient>(null);
  @Input()
  set patient(value) {
    this.$patient.next(value);
  }
  get patient(): Patient {
    return this.$patient.getValue();
  }

  @Output() switch = new EventEmitter<string>();

  constructor(private patientService: PatientService) {}

  ngOnInit(): void {}

  handleSwitchStatus(enabled: boolean): void {
    this.patientService.updateStatus(this.patient, enabled).subscribe(() => {
      if (enabled) {
        this.switch.emit('Activation effectuée');
      } else {
        this.switch.emit('Désactivation effectuée');
      }
    });
  }
}
