import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../auth/services/auth-guard.service';
import { PatientsComponent } from './pages/patients/patients.component';
import { PractitionersComponent } from './pages/practitioners/practitioners.component';
import { EditPractitionerComponent } from './pages/edit-practitioner/edit-practitioner.component';
import { EditPatientComponent } from './pages/edit-patient/edit-patient.component';
import { SynthesisOrdersComponent } from './pages/synthesis-orders/synthesis-orders.component';
import { ShowSynthesisOrderComponent } from './pages/show-synthesis-order/show-synthesis-order.component';
import { ExportsComponent } from './pages/exports/exports.component';
import { ActivationKeyComponent } from './pages/activation-key/activation-key.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'patients',
        component: PatientsComponent,
      },
      {
        path: 'practitioners',
        component: PractitionersComponent,
      },
      {
        path: 'synthesis-orders',
        component: SynthesisOrdersComponent,
      },
      {
        path: 'exports',
        component: ExportsComponent,
      },
      {
        path: 'practitioners/edit/:practitionerId',
        component: EditPractitionerComponent,
      },
      {
        path: 'patients/edit/:patientId',
        component: EditPatientComponent,
      },
      {
        path: 'activation-key',
        component: ActivationKeyComponent,
      },
      {
        path: 'synthesis-orders/show/:synthesisId',
        component: ShowSynthesisOrderComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
