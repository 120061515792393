<mat-card class="mat-elevation-z4">
  <button mat-raised-button color="primary" (click)="downloadPatientsData()">
    <mat-icon>person</mat-icon>
    Données patients
  </button>

  <button mat-raised-button color="primary" (click)="downloadLinearScores()">
    <mat-icon>assignment</mat-icon>
    Profils de santé
  </button>

  <button mat-raised-button color="primary" (click)="downloadActivationCodes()">
    <mat-icon>credit_card</mat-icon>
    Codes d'activation
  </button>

  <button mat-raised-button color="primary" (click)="downloadCompanyStatsThroughDatepicker()">
    <mat-icon>fact_check</mat-icon>
    Statistiques de génération de code par entreprise
  </button>

  <button mat-raised-button color="primary" (click)="downloadUserPortalStatsThroughDatePicker()">
    <mat-icon>contact_mail</mat-icon>
    Utilisations portail d'activation
  </button>
</mat-card>
