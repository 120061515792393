import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Country } from 'ngx-mat-intl-tel-input/lib/model/country.model';

@Component({
  selector: 'app-tel-input',
  templateUrl: './tel-input.component.html',
  styleUrls: ['./tel-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TelInputComponent),
      multi: true,
    },
  ],
})
export class TelInputComponent implements ControlValueAccessor {
  @Input() public label = '';
  @Input() public formControlName: string;
  @Input()
  get value(): string {
    return this.innerValue;
  }
  set value(value: string) {
    this.innerValue = value;
  }

  public placeholder = '';

  public $error = new BehaviorSubject<string>(null);

  private innerValue = '';

  constructor(private controlContainer: ControlContainer) {}

  /**
   * Get FormControl by it's name (formControlName directive from ReactiveForm)
   */
  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  /**
   * Handle error event from ControlErrorDirective and push the error to $error subject
   * @param error
   */
  handleError(error: string): void {
    this.$error.next(error);
  }

  // --- CONTROL VALUE ACCESSOR METHODS----

  /**
   * Store the function pass by registerOnChange
   * The pass function will tell the form that there is change in the input
   */
  private propagateChange = (_: any) => {};

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnTouched(fn: any): void {}
  // --- END OF VALUE ACCESSOR METHODS ---

  handleCountryChanged(country: Country): void {
    this.placeholder = country.placeHolder;
  }
}
