import {
  Directive,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  Self,
  EventEmitter,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { merge, Subscription } from 'rxjs';
import { FORM_ERRORS } from '../../form-errors';

@Directive({
  selector: '.app-input[appControlErrors]',
})
export class ControlErrorsDirective implements OnInit, OnDestroy {
  private valueChangesSubscription: Subscription;

  @Output() controlError = new EventEmitter<string>();
  constructor(
    @Self() private control: NgControl,
    @Inject(FORM_ERRORS) private errors
  ) {}

  /**
   * Subscribe control changes and get error if it have some
   */
  ngOnInit(): void {
    this.valueChangesSubscription = merge(
      this.control.valueChanges,
      this.control.statusChanges
    ).subscribe(() => {
      const controlErrors = this.control.errors;
      if (controlErrors) {
        const firstKey = Object.keys(controlErrors)[0];
        const text = this.errors[firstKey];
        this.setError(text);
      } else {
        this.setError(null);
      }
    });
  }

  /**
   * Emit error value to app-input component
   * @param text
   */
  setError(text: string): void {
    this.controlError.emit(text);
  }

  ngOnDestroy(): void {
    this.valueChangesSubscription.unsubscribe();
  }
}
