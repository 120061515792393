import { Component, OnInit } from '@angular/core';
import { ActivationKeyService } from '../../services/activation-key.service';

@Component({
  selector: 'app-activation-key',
  templateUrl: './activation-key.component.html',
  styleUrls: ['./activation-key.component.scss'],
})
export class ActivationKeyComponent implements OnInit {
  ordered_package_ready = false;
  input_data_full = {
    full: 1,
    treatment: 0,
    consultation: 0,
    box: 0,
  };
  all_product_types: any[] = [
    {
      name: 'Full',
      type: 'full',
      full: 1,
      treatment: 0,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Pack',
      type: 'pack',
      full: 2,
      treatment: 2,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Pack Consult',
      type: 'pack_consult',
      full: 1,
      treatment: 0,
      consultation: 1,
      box: 0,
    },
    {
      name: 'Double Full',
      type: 'double_full',
      full: 2,
      treatment: 0,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Double Full Consult',
      type: 'double_full_consult',
      full: 2,
      treatment: 0,
      consultation: 1,
      box: 0,
    },
    {
      name: 'Phytocure Program',
      type: 'phytocure_program',
      full: 2,
      treatment: 2,
      consultation: 1,
      box: 0,
    },

    {
      name: 'Special Valentine',
      type: 'special_valentine',
      full: 2,
      treatment: 2,
      consultation: 0,
      box: 0,
    },

    {
      name: 'Darwin Full',
      type: 'darwin_full',
      full: 2,
      treatment: 2,
      consultation: 0,
      box: 0,
    },

    {
      name: 'Darwin Double Full',
      type: 'darwin_double_full',
      full: 2,
      treatment: 0,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Darwin Triple Full',
      type: 'darwin_triple_full',
      full: 3,
      treatment: 0,
      consultation: 0,
      box: 0,
    },
    {
      name: 'Darwin Pack Product',
      type: 'darwin_pack_product',
      full: 2,
      treatment: 0,
      consultation: 0,
      box: 4,
    },
    {
      name: 'Special Medef87 Pack',
      type: 'special_medef87_pack_consult',
      full: 2,
      treatment: 2,
      consultation: 0,
      box: 0,
    },
  ];

  type: string;
  quantity: number;
  campaignType: string;
  companyName: string;

  constructor(private activationKeyService: ActivationKeyService) {}

  ngOnInit(): void {
    this.activationKeyService.ordered_package.subscribe((data) => {
      this.ordered_package_ready = data;
    });
  }
  generateKey() {}
}
