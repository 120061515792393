<ng-container *ngIf="$synthesisOrder | async as synthesisOrder">
  <div class="synthesis-file-container">
    <section>
      <h2>Fichier de synthèse</h2>
      <ng-container *ngIf="synthesisOrder.status === 'REQUESTED'">
        <form [formGroup]="synthesisForm" (ngSubmit)="handleSubmit()">
          <mat-form-field class="input-file-container">
            <ngx-mat-file-input formControlName="synthesisFile"
                                placeholder="Sélectionner un fichier sur votre ordinateur"></ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
          </mat-form-field>
          <button mat-raised-button color="primary" type="submit">Enregistrer</button>
        </form>
      </ng-container>
      <ng-container *ngIf="synthesisOrder.status === 'COMPLETED'">
        <label>
          <span>Fichier de synthèse</span>
        </label>
        <div>
          <span>{{synthesis?.originalFilename}}</span>
        </div>
      </ng-container>
    </section>
  </div>
</ng-container>
