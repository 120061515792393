import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';

const DATE_REGEX = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
export class DateValidator {
  static valid(control: FormControl): { [error: string]: any } {
    const dateRegex = new RegExp(DATE_REGEX);
    if (dateRegex.test(control.value)) {
      return null;
    }
    return {
      invalidDateFormat: true,
    };
  }

  static formatDateOfBirth(date: string): string {
    const dateRegex = new RegExp(DATE_REGEX);
    if (dateRegex.test(date)) {
      const dateParts = date.split('/');
      const dateObject = new Date(
        `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
      );
      return formatDate(dateObject, 'yyyy-MM-ddTHH:mm:ssZ', 'fr');
    }
    throw new Error(
      `[DateValidator] - Invalid date of birth format. Can't be process`
    );
  }
}
