import { Component, Input, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() public label = '';
  @Input() public placeholder = '';
  @Input() public disabled = false;
  @Input() public type = 'text';
  @Input() public formControlName: string;
  @Input()
  get value(): string {
    return this.innerValue;
  }
  set value(value: string) {
    this.innerValue = value;
  }

  public $error = new BehaviorSubject<string>(null);

  private innerValue = '';

  constructor(private controlContainer: ControlContainer) {}

  /**
   * Get FormControl by it's name (formControlName directive from ReactiveForm)
   */
  get control(): AbstractControl {
    return this.controlContainer.control.get(this.formControlName);
  }

  /**
   * Handle error event from ControlErrorDirective and push the error to $error subject
   * @param error
   */
  handleError(error: string): void {
    this.$error.next(error);
  }

  // --- CONTROL VALUE ACCESSOR METHODS----

  /**
   * Store the function pass by registerOnChange
   * The pass function will tell the form that there is change in the input
   */
  private propagateChange = (_: any) => {};

  onChange(value: string): void {
    this.value = value;
    this.propagateChange(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.innerValue = value;
  }

  registerOnTouched(fn: any): void {}
  // --- END OF VALUE ACCESSOR METHODS ---
}
