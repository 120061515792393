import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivationKeyService } from '../../../services/activation-key.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input('title') title: string;
  @Input('subtitle') subtitle: string;
  @Input('content_object_full') full: any;
  @Input('content_object_treatment') treatment: any;
  @Input('content_object_consultation') consultation: any;
  @Input('content_object_box') box: any;
  @Input('type') type: any;

  constructor(
    public dialog: MatDialog,
    private activationKeyService: ActivationKeyService
  ) {}

  openDialog(data: string) {
    this.activationKeyService.package_data.next({
      title: this.title,
      full: this.full,
      treatment: this.treatment,
      consultation: this.consultation,
      box: this.box,
      type: this.type,
    });

    const dialogRef = this.dialog.open(DialogContentExampleDialog);

    dialogRef.afterClosed().subscribe((result) => {});
  }

  ngOnInit(): void {}
}
@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'dialog-content-example-dialog.html',
  styleUrls: ['dialog-content-example-dialog.css'],
})
export class DialogContentExampleDialog implements OnInit {
  package_data_sub: Subscription;
  package_info: any;
  counter = 1;

  // name: string;
  // type: string;
  // full: number;
  // treatment: number;
  // consultation: number;
  // box: number;
  constructor(private activationKeyService: ActivationKeyService) {}

  ngOnInit(): void {
    this.package_data_sub = this.activationKeyService.package_data.subscribe(
      (data) => {
        this.package_info = data;
      }
    );
  }
  onIncrease() {
    this.counter++;
  }
  onDecrease() {
    if (this.counter > 0) this.counter--;
  }
  generate(copmpanyType, campaignType, quantity, packageType) {
    let package_temp_data = {
      type: packageType,
      quantity: parseInt(quantity),
      campaignType: campaignType,
      campanyType: copmpanyType,
    };
    console.log(package_temp_data);

    this.activationKeyService
      .generatePackage(package_temp_data)
      .subscribe((data) => {
        console.log(data);
        this.activationKeyService.ordered_package.next(data);
      });
  }
}
