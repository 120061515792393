<div class="practitioner-form-container">
  <form [formGroup]="practitionerForm" (ngSubmit)="handleSubmit()">
    <section>
      <h2>Informations</h2>
      <div class="input-row">
        <app-input formControlName="firstName" label="Prénom" [value]="getFormValue('firstName')"></app-input>
        <app-input formControlName="lastName" label="Nom" [value]="getFormValue('lastName')"></app-input>
        <app-input formControlName="identificationNumber" label="N° RPPS" [value]="getFormValue('identificationNumber')"></app-input>
      </div>
      <div class="input-row">
        <app-input formControlName="email" label="E-mail" [value]="getFormValue('email')"></app-input>
        <app-input formControlName="phoneNumber" label="Téléphone" [value]="getFormValue('phoneNumber')"></app-input>
      </div>
    </section>
    <section>
      <h2>Adresse</h2>
      <div class="input-row">
        <app-input formControlName="address" label="Adresse" [value]="getFormValue('address')"></app-input>
        <app-input formControlName="zipCode" label="Code postal" [value]="getFormValue('zipCode')"></app-input>
      </div>
      <div class="input-row">
        <app-input formControlName="city" label="Ville" [value]="getFormValue('city')"></app-input>
        <app-input formControlName="country" label="Pays" [value]="getFormValue('country')"></app-input>
      </div>
    </section>
    <section>
      <h2>Changer mot de passe</h2>
      <div class="input-row" formGroupName="passwords">
        <app-input formControlName="password" label="Nouveau mot de passe" type="password"></app-input>
        <app-input formControlName="confirmationPassword" label="Confirmer mot de passe" type="password"></app-input>
      </div>
    </section>
    <button mat-raised-button color="primary" type="submit">Enregistrer</button>
  </form>
</div>
