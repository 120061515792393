import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Patient } from '../../../core/models/patient.model';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-patient-record',
  templateUrl: './patient-record.component.html',
  styleUrls: ['./patient-record.component.scss'],
})
export class PatientRecordComponent implements OnInit {
  public $patient = new BehaviorSubject<Patient>(null);
  @Input()
  set patient(value) {
    this.$patient.next(value);
  }
  get patient(): Patient {
    return this.$patient.getValue();
  }

  @Output() registerRequest = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  handleRegisterRequest(): void {
    this.registerRequest.emit();
  }
}
