import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Practitioner } from '../../../core/models/practitioner.model';
import { PractitionerService } from '../../services/practitioner.service';

@Component({
  selector: 'app-practitioner-switch-status',
  templateUrl: './practitioner-switch-status.component.html',
  styleUrls: ['./practitioner-switch-status.component.scss'],
})
export class PractitionerSwitchStatusComponent implements OnInit {
  public $practitioner = new BehaviorSubject<Practitioner>(null);
  @Input()
  set practitioner(value) {
    this.$practitioner.next(value);
  }
  get practitioner(): Practitioner {
    return this.$practitioner.getValue();
  }

  @Output() switch = new EventEmitter<string>();

  constructor(private practitionerService: PractitionerService) {}

  ngOnInit(): void {}

  handleSwitchStatus(enabled: boolean): void {
    this.practitionerService
      .updateStatus(this.practitioner, enabled)
      .subscribe(() => {
        if (enabled) {
          this.switch.emit('Activation effectuée');
        } else {
          this.switch.emit('Désactivation effectuée');
        }
      });
  }
}
