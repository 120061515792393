import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const PASSWORD_REGEX = /^.*(?=.{10,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export class PasswordValidator {
  static strength(control: FormControl): { [error: string]: any } {
    const passwordRegex = new RegExp(PASSWORD_REGEX);
    if (passwordRegex.test(control.value)) {
      return null;
    }
    return {
      strenghPassword: true,
    };
  }

  static match(controlName: string, matchingControlName: string): ValidatorFn {
    return (group: FormGroup): { [error: string]: any } => {
      if (
        group.get(controlName).value !== group.get(matchingControlName).value
      ) {
        const matchingControl = group.controls[matchingControlName];
        matchingControl.setErrors({ matchPassword: true });
        return {
          matchPassword: true,
        };
      }
      return null;
    };
  }

  static setValidators(
    passwordsGroup: FormGroup,
    passwordName: string,
    confirmPasswordName: string,
    required: boolean
  ): Observable<any> {
    const passwordControl = passwordsGroup.get(passwordName);
    const confirmPasswordControl = passwordsGroup.get(confirmPasswordName);

    if (required) {
      passwordControl.setValidators([Validators.required]);
    }

    return passwordControl.valueChanges.pipe(
      tap((password) => {
        const passwordValidators = [];
        if (required) {
          passwordValidators.push(Validators.required);
          passwordControl.setValidators(passwordValidators);
        }
        if (password) {
          passwordValidators.push(PasswordValidator.strength);
          passwordControl.setValidators(passwordValidators);
          confirmPasswordControl.setValidators([Validators.required]);
          passwordsGroup.setValidators([
            PasswordValidator.match('password', 'confirmationPassword'),
          ]);
        } else {
          if (!required) {
            passwordControl.clearValidators();
          }
          confirmPasswordControl.clearValidators();
          passwordsGroup.clearValidators();
          confirmPasswordControl.updateValueAndValidity();
          passwordsGroup.updateValueAndValidity();
        }
      })
    );
  }
}
