<div class="patient-form-container">
  <form [formGroup]="patientForm" (ngSubmit)="handleSubmit()" appSubmitForm>
    <section>
      <h2>{{translation + '.INFO' | translate:{value: ($patient | async).patientCode} }}</h2>
      <div class="input-row">
        <mat-radio-group formControlName="gender">
          <mat-radio-button value="M">Homme</mat-radio-button>
          <mat-radio-button value="F">Femme</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="input-row">
        <app-input formControlName="firstName" label="Prénom" [value]="getFormValue('firstName')"></app-input>
        <app-input formControlName="lastName" label="Nom" [value]="getFormValue('lastName')"></app-input>
        <app-input formControlName="socialNumber" label="N° Sécurité sociale" [value]="getFormValue('socialNumber')"></app-input>
      </div>
      <div class="input-row">
        <app-input formControlName="email" label="E-mail" [value]="getFormValue('email')"></app-input>
        <app-input formControlName="dateOfBirth" label="Date de naissance" [value]="getFormValue('dateOfBirth')"></app-input>
        <app-tel-input formControlName="phoneNumber" label="Téléphone"></app-tel-input>
      </div>
    </section>
    <section>
      <h2>{{translation + '.PASSWORD.TITLE' | translate}}</h2>
      <div class="input-row" formGroupName="passwords">
        <app-input formControlName="password" [label]="translation + '.PASSWORD.PASSWORD' | translate" type="password"></app-input>
        <app-input formControlName="confirmationPassword" [label]="translation + '.PASSWORD.CONFIRM' | translate" type="password"></app-input>
      </div>
    </section>
    <button mat-raised-button color="primary" type="submit" [disabled]="patientForm.invalid">{{translation + '.SUBMIT' | translate}}</button>
  </form>
</div>
