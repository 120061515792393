import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SynthesisService } from '../../services/synthesis.service';
import { SynthesisOrder } from '../../../core/models/synthesis-order.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-show-synthesis-order',
  templateUrl: './show-synthesis-order.component.html',
  styleUrls: ['./show-synthesis-order.component.scss'],
})
export class ShowSynthesisOrderComponent implements OnInit {
  public synthesisOrder: SynthesisOrder;
  public loaded = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly synthesisService: SynthesisService,
    private readonly snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.fetchSynthesisOrder();
  }

  fetchSynthesisOrder(): void {
    this.loaded = false;
    this.route.paramMap
      .pipe(
        take(1),
        map((paramMap) => {
          if (paramMap.get('synthesisId')) {
            return paramMap.get('synthesisId');
          }
          throwError('No synthesis order id provide');
        }),
        switchMap((synthesisId) => {
          return this.synthesisService.getOrderById(synthesisId);
        })
      )
      .subscribe((synthesisOrder) => {
        this.synthesisOrder = synthesisOrder;
        this.loaded = true;
      });
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, '', { duration: 10000 });
  }

  handleFileUploaded(): void {
    this.fetchSynthesisOrder();
    this.openSnackBar('Fichier enregistré');
  }
}
